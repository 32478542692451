import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

const serviceURL = config.SERVICE_TRACEABILITY_URL;






// =============================================== Check List APIS ============================================
const CheckListSlice = createSlice({
    name : "CheckList",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetCheckListState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                saveCheckListData.pending , 
                updateCheckListData.pending , 
                deleteCheckListData.pending , 
                saveUseCheckListData.pending , 
                updateUseCheckListData.pending , 
                deleteUseCheckListData.pending , 
                saveAssignCheckList.pending , 
                saveFavoriteCheckList.pending ,

                saveSOPData.pending , 
                updateSOPData.pending ,
                deleteSOPData.pending , 
                saveUseSOPData.pending,
                updateUseSOPData.pending,
                deleteUseSOPData.pending,
                saveAssignSOP.pending , 
                saveFavoriteSOP.pending,

                saveManualData.pending,
                updateManualData.pending,
                deleteManualData.pending,
                saveUseManualData.pending,
                updateUseManualData.pending,
                deleteUseManualData.pending,
                saveAssignManual.pending,
                saveFavoriteManual.pending,
            ),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(
                saveCheckListData.fulfilled, 
                updateCheckListData.fulfilled , 
                deleteCheckListData.fulfilled , 
                saveUseCheckListData.fulfilled, 
                updateUseCheckListData.fulfilled , 
                deleteUseCheckListData.fulfilled , 
                saveAssignCheckList.fulfilled , 
                saveFavoriteCheckList.fulfilled ,

                saveSOPData.fulfilled , 
                updateSOPData.fulfilled , 
                deleteSOPData.fulfilled , 
                saveUseSOPData.fulfilled,
                deleteUseSOPData.fulfilled,
                updateUseSOPData.fulfilled,
                saveAssignSOP.fulfilled , 
                saveFavoriteSOP.fulfilled,

                saveManualData.fulfilled,
                updateManualData.fulfilled,
                deleteManualData.fulfilled,
                saveUseManualData.fulfilled,
                updateUseManualData.fulfilled,
                deleteUseManualData.fulfilled,
                saveAssignManual.fulfilled,
                saveFavoriteManual.fulfilled,
            ),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(
                saveCheckListData.rejected , 
                updateCheckListData.rejected , 
                deleteCheckListData.rejected , 
                saveUseCheckListData.rejected , 
                updateUseCheckListData.rejected , 
                deleteUseCheckListData.rejected , 
                saveAssignCheckList.rejected , 
                saveFavoriteCheckList.rejected , 

                saveSOPData.rejected , 
                updateSOPData.rejected , 
                deleteSOPData.rejected ,
                saveUseSOPData.rejected, 
                deleteUseSOPData.rejected,
                updateUseSOPData.rejected,
                saveAssignSOP.rejected , 
                saveFavoriteSOP.rejected,

                saveManualData.rejected,
                updateManualData.rejected,
                deleteManualData.rejected,
                saveUseManualData.rejected,
                updateUseManualData.rejected,
                deleteUseManualData.rejected,
                saveAssignManual.rejected,
                saveFavoriteManual.rejected,
            ),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 

//  =========================================  CheckList APIS ============================================


// get data from CheckList
export const getCheckListData = createAsyncThunk( "checkList/get", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { page , size , sortBy , sortOrder , filters } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { page, size, sortBy, sortOrder, filters },
        };

        const responce = await axios.get(`${serviceURL}/checklists/getAllChecklists`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// save Data in CheckList
export const saveCheckListData = createAsyncThunk( "checkList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/checklists/createChecklist`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Data in CheckList
export const updateCheckListData = createAsyncThunk( "checkList/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { id , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/checklists/${id}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from CheckList
export const deleteCheckListData = createAsyncThunk( "checkList/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/checklists/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})






// =============================================== USE Check List APIS ============================================


// get data from  useCheckList
export const getUseCheckListData = createAsyncThunk( "useCheckList/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const {userId , page , size , sortBy , sortOrder } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {userId , page, size , sortBy , sortOrder }
        };

        const responce = await axios.get(`${serviceURL}/useOfChecklists/getAllUserChecklistRecords`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in CheckList
export const saveUseCheckListData1 = createAsyncThunk("useCheckList/createTemplate", async (data, { rejectWithValue, getState }) => {
      try {
        const { checklistId } = data;
        const state = getState();
        const token = state.auth.token;
          
        const config = {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
          params: {
            checklistId: checklistId,
          },
        };
  
        const response = await axios.post(`${serviceURL}/useOfChecklists/useChecklist`, {}, config);
        return response.data;
  
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const saveUseCheckListData = createAsyncThunk("useCheckList/save", async (data, { rejectWithValue, getState }) => {
        try {
            const { files, ...restData } = data;
            const state = getState();
            const token = state.auth.token;

            const formData = new FormData();
            formData.append("record", JSON.stringify(restData)); 

            // Append each file to FormData properly
            if (files && files.length > 0) {
                files.forEach((file) => {
                    formData.append("files", file); 
                });
            }

            const queryParams = new URLSearchParams();

            const config = { headers: { Authorization: `Bearer ${token}` },};

            const response = await axios.post(`${serviceURL}/useOfChecklists/${restData.id}?${queryParams.toString()}`, formData, config );

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);



// update Data in CheckList
export const updateUseCheckListData = createAsyncThunk( "useCheckList/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists/${data.userId}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from CheckList
export const deleteUseCheckListData = createAsyncThunk( "useCheckList/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/useOfChecklists/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




// ================================================ Assign and Favorite checklist API =============================================

// Save Assign CheckList Data
export const saveAssignCheckList = createAsyncThunk( "assignCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"}};

        const responce = await axios.post(`${serviceURL}/useOfChecklists/assign`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Save Favorite CheckList Data
export const saveFavoriteCheckList = createAsyncThunk( "FavoriteCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { isRemove , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { isRemove :isRemove }
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists/markAsFavChecklist`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// get data from  useCheckList
export const getFavAndAssignedCheckList = createAsyncThunk( "favAndAssignedCheckList/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfChecklists/getAllFavAndAssignedChecklists/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

















//  =========================================  SOP APIS ==========================================================================================================================================================================================================================================================================================================================


// get data from SOP
export const getSOPData = createAsyncThunk( "SOP/get", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { page , size , sortBy , sortOrder , filters } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { page, size, sortBy, sortOrder, filters },
        };

        const responce = await axios.get(`${serviceURL}/sops/getAllSOPs`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// save Data in SOP
export const saveSOPData = createAsyncThunk( "SOP/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/sops/createSOP`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Data in SOP
export const updateSOPData = createAsyncThunk( "SOP/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sopId , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/SOPs/${sopId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from SOP
export const deleteSOPData = createAsyncThunk( "SOP/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/sops/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// ================================ SOP Images and PDF ===================================

// upload sop images
export const uploadSOPImg = createAsyncThunk( "SOP/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/uploadSOPImage`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// upload sop PDF
export const uploadSOPPdf = createAsyncThunk( "SOP/UploadPdf", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/uploadSOPPdf`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// upload sop section image
export const uploadSOPSectionImg = createAsyncThunk( "SOPSection/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/uploadSectionImage`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// upload sop section PDF
export const uploadSOPSectionPdf = createAsyncThunk( "SOPSection/UploadPDF", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/uploadSectionPdf`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// upload sop task images
export const uploadSOPTaskImg = createAsyncThunk( "SOPTask/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/tasks/${data.taskKey}/uploadTaskImage`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// upload sop Task PDF
export const uploadSOPTaskPdf = createAsyncThunk( "SOPTask/UploadPdf", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/sops/${data.sopId}/sections/${data.sectionKey}/tasks/${data.taskKey}/uploadTaskPdf`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// =============================================== USE SOP APIS ============================================


// get data from  useSOP
export const getUseSOPData = createAsyncThunk( "useSOP/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const {userId , page , size , sortBy , sortOrder } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {userId , page, size , sortBy , sortOrder }
        };

        const responce = await axios.get(`${serviceURL}/useOfSOPs/getAllUserSOPRecords`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// save Data in SOP
export const saveUseSOPData1 = createAsyncThunk( "useSOP/createTemplate", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sopId } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {sopId : sopId}
        };

        const responce = await axios.post(`${serviceURL}/useOfSOPs/useSOP`,{}, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



export const saveUseSOPData = createAsyncThunk("useSOP/save", async (data, { rejectWithValue, getState }) => {
        try {
            const { files, ...restData } = data;
            const state = getState();
            const token = state.auth.token;

            const formData = new FormData();
            formData.append("record", JSON.stringify(restData));

            // Append each file to FormData properly
            if (files && files.length > 0) {
                files.forEach((file) => {
                    formData.append("files", file); 
                });
            }

            // Construct query parameters properly
            const queryParams = new URLSearchParams();

            const config = { headers: { Authorization: `Bearer ${token}` }, };

            const response = await axios.post(`${serviceURL}/useOfSOPs/${restData.id}?${queryParams.toString()}`,formData, config );

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);


// update Data in SOP
export const updateUseSOPData = createAsyncThunk( "useSOP/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sopId , ...restData} = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/useOfSOPs/${sopId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete data from SOP
export const deleteUseSOPData = createAsyncThunk( "useSOP/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/useOfSOPs/${data.sopId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// ================================================ Assign and Favorite SOP API =============================================

// Save Assign SOP Data
export const saveAssignSOP = createAsyncThunk( "assignSOP/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"}};

        const responce = await axios.post(`${serviceURL}/useOfSOPs/assign`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Save Favorite SOP Data
export const saveFavoriteSOP = createAsyncThunk( "FavoriteSOP/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { isRemove , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { isRemove :isRemove }
        };

        const responce = await axios.post(`${serviceURL}/useOfSOPs/markAsFavSOP`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// get data from  useSOP
export const getFavAndAssignedSOP = createAsyncThunk( "favAndAssignedSOP/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfSOPs/getAllFavAndAssignedSOPs/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})














//  =========================================  manual APIS ==========================================================================================================================================================================================================================================================================================================================

// get data from Manual
export const getManualData = createAsyncThunk( "Manual/get", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { page , size , sortBy , sortOrder , filters } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { page, size, sortBy, sortOrder, filters },
        };

        const responce = await axios.get(`${serviceURL}/manuals/getAllManuals`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in Manual
export const saveManualData = createAsyncThunk( "Manual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/manuals/createManual`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in Manual
export const updateManualData = createAsyncThunk( "Manual/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { manualId , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/manuals/${manualId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete data from Manual
export const deleteManualData = createAsyncThunk( "Manual/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/manuals/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


//================================== Manual PDF============================ 


// upload Manual PDF
export const uploadManualPdf = createAsyncThunk( "Manual/UploadPdf", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", }};

        const responce = await axios.post(`${serviceURL}/manuals/${data.manualId}/uploadManual`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// =============================================== USE Manual APIS ============================================


// get data from  useManual
export const getUseManualData = createAsyncThunk( "useManual/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/Manuals/useOfManuals/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in Manual
export const saveUseManualData = createAsyncThunk( "useManual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { manualId , userName , ...restData} = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {manualId : manualId , username : userName}
        };

        const responce = await axios.post(`${serviceURL}/useOfManuals/useManual`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in Manual
export const updateUseManualData = createAsyncThunk( "useManual/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { manualId , ...restData} = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/useOfManuals/${manualId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete data from Manual
export const deleteUseManualData = createAsyncThunk( "useManual/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/useOfManuals/${data.manualId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// ================================================ Assign and Favorite Manual API =============================================

// Save Assign Manual Data
export const saveAssignManual = createAsyncThunk( "assignManual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"}};

        const responce = await axios.post(`${serviceURL}/useOfManuals/assign`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// Save Favorite Manual Data
export const saveFavoriteManual = createAsyncThunk( "FavoriteManual/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { isRemove , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { isRemove :isRemove }
        };

        const responce = await axios.post(`${serviceURL}/useOfManuals/markAsFavManual`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// get data from  useManual
export const getFavAndAssignedManual = createAsyncThunk( "favAndAssignedManual/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfManuals/getAllFavAndAssignedManuals/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})










export {
    CheckListSlice,
}

export const {resetCheckListState} = CheckListSlice.actions;



// {
//     "id": "6791ffbf5b8a7a1deace6d9a",
//     "userId": null,
//     "checklistId": "6791ffa75b8a7a1deace6d99",
//     "version": null,
//     "comment": null,
//     "sections": [
//         {
//             "key": "e4e45af5-7211-4e9e-b6c1-e8c93a69cbf3",
//             "tasks": [
//                 {
//                     "key": "c55c3774-3221-4161-a7a2-356b05c11e7a",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "49cd31fa-bbd1-46f6-abfd-a0072a2e230b",
//                             "tick": true
//                         }
//                     ]
//                 },
//                 {
//                     "key": "f2fc2349-3847-4f86-b051-04125b12064f",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "f37387bd-ff59-4c29-b7c4-7406cb58060e",
//                             "tick": true
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "key": "5cee3440-316a-4971-8d50-12cf70dc7286",
//             "tasks": [
//                 {
//                     "key": "5a000517-df65-455f-ba91-ed5bcae33032",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "e483e1dc-79a7-4627-aea1-a198aeb98584",
//                             "tick": true
//                         }
//                     ]
//                 },
//                 {
//                     "key": "2c0ab7de-8fa9-4f46-9538-5190057725e4",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "0d7aa1e0-a9f9-4a65-8f4b-aae714aed5f1",
//                             "tick": true
//                         }
//                     ]
//                 }
//             ]
//         }
//     ],
//     "attachmentUrls": null,
//     "createdBy": "pankajkumar@technumopus.com",
//     "updatedBy": "pankajkumar@technumopus.com",
//     "deletedBy": null,
//     "createdAt": "2025-01-23T08:36:55.991+00:00",
//     "updatedAt": "2025-01-23T08:37:19.652+00:00",
//     "deletedAt": null,
//     "deleted": false
// }

// {
//     "id": "679200415b8a7a1deace6d9c",
//     "userId": null,
//     "checklistId": "679200345b8a7a1deace6d9b",
//     "version": null,
//     "comment": null,
//     "sections": [
//         {
//             "key": "df221bcb-479d-4f05-8c6f-313d9ab2ac85",
//             "tasks": [
//                 {
//                     "key": "50ecd465-dfeb-4a67-b6c6-8f53ec8269c7",
//                     "taskComment": null,
//                     "tickBox": [
//                         {
//                             "key": "70adfe96-5ea3-4eeb-bdfa-9bee69446751",
//                             "tick": true
//                         }
//                     ]
//                 }
//             ]
//         }
//     ],
//     "attachmentUrls": null,
//     "createdBy": "pankajkumar@technumopus.com",
//     "updatedBy": "pankajkumar@technumopus.com",
//     "deletedBy": null,
//     "createdAt": "2025-01-23T08:39:16.459+00:00",
//     "updatedAt": "2025-01-23T08:39:29.753+00:00",
//     "deletedAt": null,
//     "deleted": false
// }